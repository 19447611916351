/* .navbar {
    background-color: black;
    position: fixed;
    z-index: 1;
    width: 100%;
} */

.logo {
    width: 7%;
    padding: 0.5em 0;
    margin: 0 1em;
}

.logo img {
    width: 100%;
}

.menu li {
    width: fit-content;
    margin: -1em 0em 2.5em 0em;
    padding: 0em 1em;
    height: 0vh;
    letter-spacing: 0.1em;
    word-spacing: 0.1em;
}

.menu li a:hover {
    border-bottom: 0.1em solid #66fcf1;
}

.menu a {
    font-weight: bold !important;
}

.navmenu {
    text-align: center;
}

.navbar-toggler-icon i {
    color: #66fcf1;
    font-size: 1.5em;
    padding: auto;
}


/* Media Query */

@media screen and (max-width: 575.98px) {
    .logo {
        width: 40%;
        padding: 1em 0;
        margin: 0 1em;
    }
    .navmenu {
        text-align: center;
        margin: -2em 0em;
    }
    .navbar-toggler-icon i {
        margin: -1em 0em;
    }
    .menu {
        width: 100%;
        margin: 0em 0%;
    }
    .menu li {
        color: green;
        width: 100%;
        text-align: center;
        margin: 0em 0em 2.5em 0em;
        height: 0vh;
        letter-spacing: 0.1em;
        word-spacing: 0.1em;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
    .logo {
        width: 20%;
        padding: 1em 0;
        margin: 0 1em;
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .logo {
        width: 15%;
        padding: 1em 0;
        margin: 0 1em;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
    .logo {
        width: 10%;
        padding: 1em 0;
        margin: 0 1em;
    }
}


/* 
@media screen and (min-width:1200px) and (max-width:1599.98px) {}
 */


/* Ends */