.mainwork {
  margin: 1em 0;
}

.mainhead {
  text-align: center;
}

.mainhead h1 {
  font-size: 50px;
}

.mainhead h4 {
  width: 60%;
  margin: auto;
}

.mainbody .row {
  width: 100%;
  margin: 2em auto 3em auto;
}

.mainbody .col-lg-6 {
  padding: 1em auto;
  margin: 1em auto;
}

.mainbody .pcards {
  border-radius: 0.5em;
  width: 100%;
  height: 490px;
  position: relative;
}

.mainbody .pcards .pcardsimg {
  width: 100%;
  position: absolute;
  height: 490px;
  top: 0;
  left: 0;
}

.mainbody .pcards .pcardsimg img {
  width: 100%;
  height: 490px;
  border-radius: 1em;
  filter: drop-shadow(0px 0px 5px #ffffff77);
}
