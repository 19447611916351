footer {
    background-color: rgba(128, 128, 128, 0.212);
}

.footer .col-lg-4 {
    text-align: justify;
    padding: 1em;
}

.footer .col-lg-2 {
    text-align: left;
}

.logoimg {
    width: 40%;
    margin: 1em 30%;
}

.services h2,
.contact h2 {
    padding-top: 1em;
    font-size: 30px;
    letter-spacing: 1px;
}

.services ul li {
    padding-top: 1.5em;
    font-weight: bold;
}

.contact ul li {
    padding-top: 1.5em;
    font-weight: bold;
}

.services ul li a:hover {
    letter-spacing: 0.01em;
}

.contact ul li span i {
    margin-right: 0.8em;
    padding-top: 0.5em;
    font-size: 1.5em;
}

.contact ul li span .fa-map-marker-alt {
    padding-top: 1em;
}

.social ul li {
    display: inline-block;
    font-size: 1.5em;
    margin: 1em 1em 0 1em;
}

.quotep1 {
    width: 40%;
    margin: auto;
}

.copy {
    padding: 0.5em 0;
    border-top: 0.1em solid rgb(255, 255, 255, 0.3);
}


/* Social Media Icons on Hover */

.fa-facebook-f:hover {
    color: #4267b2;
}

.fa-instagram:hover {
    color: #8a3ab9;
}

.fa-twitter:hover {
    color: #00acee;
}

.fa-linkedin-in:hover {
    color: #0e76a8;
}

.fa-youtube:hover {
    color: #c4302b;
}


/* ends */


/* Media Query */

@media screen and (max-width:575.98px) {
    .social ul li {
        margin: 1em 0.5em 0 0.5em;
    }
    .logoimg {
        width: 50%;
        margin: 1em 25%;
    }
}

@media screen and (min-width:576px) and (max-width:767.98px) {
    footer {
        text-align: justify;
        background-color: rgba(128, 128, 128, 0.212);
    }
    .footer .col-lg-4 {
        padding: 1em;
    }
    .logoimg {
        width: 40%;
        margin: 1em 30%;
    }
    .services h2,
    .contact h2 {
        padding-top: 1em;
        font-size: 30px;
        letter-spacing: 1px;
    }
    .services ul li {
        padding-top: 1.5em;
        font-weight: bold;
    }
    .contact ul li {
        padding-top: 1.5em;
        font-weight: bold;
    }
    .services ul li a:hover {
        letter-spacing: 0.01em;
    }
    .contact ul li span i {
        margin-right: 0.8em;
        padding-top: 0.5em;
        font-size: 1.5em;
    }
    .contact ul li span .fa-map-marker-alt {
        padding-top: 1em;
    }
    .social ul li {
        display: inline-block;
        font-size: 1.5em;
        margin: 1em 1em 0 1em;
    }
    .quotep1 {
        width: 40%;
        margin: auto;
    }
    .copy {
        padding: 0.5em 0;
        border-top: 0.1em solid rgb(255, 255, 255, 0.3);
    }
    /* Social Media Icons on Hover */
    .fa-facebook-f:hover {
        color: #4267b2;
    }
    .fa-instagram:hover {
        color: #8a3ab9;
    }
    .fa-twitter:hover {
        color: #00acee;
    }
    .fa-linkedin-in:hover {
        color: #0e76a8;
    }
    .fa-youtube:hover {
        color: #c4302b;
    }
}

@media screen and (min-width:992px) and (max-width:1199.98px) {
    footer {
        text-align: left;
    }
    .footer .col-lg-4 {
        padding: 1em;
    }
    .logoimg {
        width: 50%;
        margin: 1em 25%;
    }
    .services h2,
    .contact h2 {
        padding-top: 1em;
        font-size: 24px;
        letter-spacing: 1px;
    }
    .services ul li {
        padding-top: 1em;
        font-weight: bold;
    }
    .contact ul li {
        padding-top: 1em;
        font-weight: bold;
    }
    .contact ul li span i {
        margin-left: -1.2em;
        margin-right: 0.5em;
        padding-top: 1em;
        font-size: 1em;
    }
    .contact ul li span .fa-map-marker-alt {
        padding-top: 2em;
    }
    .social ul li {
        display: inline-block;
        font-size: 1.5em;
        margin: 1em 1em 0 1em;
    }
    .quotep1 {
        width: 60%;
        margin: auto;
    }
}

@media screen and (min-width:1200px) and (max-width:1599.98px) {}


/* Ends */