@import url("https://fonts.googleapis.com/css2?family=Crimson+Text&family=Source+Sans+Pro&display=swap");
* {
    margin: 0%;
    padding: 0%;
    list-style: none;
    box-sizing: border-box;
}


/* scrollbar */

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.2em;
    display: none;
}

::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 1em;
}


/* ends */


/* Wrap Content and Footer */

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.footer {
    height: fit-content;
}

.content {
    flex-grow: 1;
}

.content .container-fluid {
    width: 95%;
    margin: auto;
}


/* ends */

body {
    margin: 0%;
    padding: 0%;
    color: white;
    background-color: #0b0c10;
}

.row {
    width: 100%;
    margin: auto;
}

a {
    text-decoration: none;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
}

a:hover {
    text-decoration: none;
    color: #66fcf1;
    font-weight: bold;
}


/* font size and style */

h1 {
    font-size: 40px;
    font-family: "Crimson Text", serif;
}

h2 {
    font-size: 28px;
    font-family: "Crimson Text", serif;
}

h3 {
    font-size: 24px;
    font-family: "Crimson Text", serif;
}

h4 {
    font-size: 20px;
    font-family: "Crimson Text", serif;
}

h5 {
    font-size: 18px;
    font-family: "Crimson Text", serif;
}

p {
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
}


/* Ends */


/* Background Video */

.overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    background-color: rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    overflow: hidden;
}

.bgvideo img {
    position: fixed;
    object-fit: cover;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: -2;
}


/* ends */


/* Go To Top */

.gototop {
    position: fixed;
    bottom: 100px;
    right: 200px;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.gototop.active {
    opacity: 1;
    visibility: visible;
}

.gototop a {
    width: 50px;
    height: 50px;
    display: table;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.gototop a i {
    height: 50px;
    display: table-cell;
    vertical-align: middle;
}

.gototop a:hover,
.gototop a:active,
.gototop a:focus {
    text-decoration: none;
    outline: none;
}


/* ends */