.glitch-wrapper {
    margin: 10vh 0;
    text-align: center;
    animation: glitch 3s linear infinite;
}

.glitch-text {
    font-weight: bold;
}

.glitch-text::before {
    content: 'ERROR 404';
    color: white;
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    display: block;
    position: absolute;
    z-index: -1;
    font-size: 100px;
    left: 0px;
    right: 0px;
    margin-top: -40px;
    opacity: 0.5;
}

.glitch-text {
    position: relative;
    z-index: 2;
    opacity: .9;
    margin: 0 auto;
    text-shadow: 0px 0px 3px white;
    animation: glitch 3s infinite;
    cursor: pointer;
    font-size: 50px;
    color: white;
}


/* JUST RANDOM VALUES HERE! */

@keyframes glitch {
    3% {
        text-shadow: 7px 7px 0px red;
    }
    6% {
        text-shadow: -9px 4px 0px blue;
        transform: translate(4px, -10px) skewX(3240deg);
    }
    7% {
        text-shadow: 3px -4px 0px green;
    }
    9% {
        text-shadow: 0px 0px 3px white;
        transform: translate(0px, 0px) skewX(0deg);
    }
    2%,
    54% {
        transform: translateX(0px) skew(0deg);
    }
    55% {
        transform: translate(-2px, 6px) skew(-5530deg);
    }
    56% {
        text-shadow: 0px 0px 3px white;
        transform: translate(0px, 0px) skew(0deg);
    }
    57% {
        text-shadow: 7px 7px 0px red;
        transform: translate(4px, -10px) skew(-70deg);
    }
    58% {
        text-shadow: 0px 0px 3px white;
        transform: translate(0px, 0px) skew(0deg);
    }
    62% {
        text-shadow: 3px -4px 0px green;
        transform: translate(0px, 20px) skew(0deg);
    }
    63% {
        text-shadow: 0px 0px 3px white;
        transform: translate(4px, -2px) skew(0deg);
    }
    64% {
        transform: translate(1px, 3px) skew(-230deg);
    }
    65% {
        transform: translate(-7px, 2px) skew(120deg);
    }
    66% {
        transform: translate(0px, 0px) skew(0deg);
    }
}