@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&family=Source+Sans+Pro&display=swap);
/* .navbar {
    background-color: black;
    position: fixed;
    z-index: 1;
    width: 100%;
} */

.logo {
    width: 7%;
    padding: 0.5em 0;
    margin: 0 1em;
}

.logo img {
    width: 100%;
}

.menu li {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: -1em 0em 2.5em 0em;
    padding: 0em 1em;
    height: 0vh;
    letter-spacing: 0.1em;
    word-spacing: 0.1em;
}

.menu li a:hover {
    border-bottom: 0.1em solid #66fcf1;
}

.menu a {
    font-weight: bold !important;
}

.navmenu {
    text-align: center;
}

.navbar-toggler-icon i {
    color: #66fcf1;
    font-size: 1.5em;
    padding: auto;
}


/* Media Query */

@media screen and (max-width: 575.98px) {
    .logo {
        width: 40%;
        padding: 1em 0;
        margin: 0 1em;
    }
    .navmenu {
        text-align: center;
        margin: -2em 0em;
    }
    .navbar-toggler-icon i {
        margin: -1em 0em;
    }
    .menu {
        width: 100%;
        margin: 0em 0%;
    }
    .menu li {
        color: green;
        width: 100%;
        text-align: center;
        margin: 0em 0em 2.5em 0em;
        height: 0vh;
        letter-spacing: 0.1em;
        word-spacing: 0.1em;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
    .logo {
        width: 20%;
        padding: 1em 0;
        margin: 0 1em;
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .logo {
        width: 15%;
        padding: 1em 0;
        margin: 0 1em;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
    .logo {
        width: 10%;
        padding: 1em 0;
        margin: 0 1em;
    }
}


/* 
@media screen and (min-width:1200px) and (max-width:1599.98px) {}
 */


/* Ends */
footer {
    background-color: rgba(128, 128, 128, 0.212);
}

.footer .col-lg-4 {
    text-align: justify;
    padding: 1em;
}

.footer .col-lg-2 {
    text-align: left;
}

.logoimg {
    width: 40%;
    margin: 1em 30%;
}

.services h2,
.contact h2 {
    padding-top: 1em;
    font-size: 30px;
    letter-spacing: 1px;
}

.services ul li {
    padding-top: 1.5em;
    font-weight: bold;
}

.contact ul li {
    padding-top: 1.5em;
    font-weight: bold;
}

.services ul li a:hover {
    letter-spacing: 0.01em;
}

.contact ul li span i {
    margin-right: 0.8em;
    padding-top: 0.5em;
    font-size: 1.5em;
}

.contact ul li span .fa-map-marker-alt {
    padding-top: 1em;
}

.social ul li {
    display: inline-block;
    font-size: 1.5em;
    margin: 1em 1em 0 1em;
}

.quotep1 {
    width: 40%;
    margin: auto;
}

.copy {
    padding: 0.5em 0;
    border-top: 0.1em solid rgb(255, 255, 255, 0.3);
}


/* Social Media Icons on Hover */

.fa-facebook-f:hover {
    color: #4267b2;
}

.fa-instagram:hover {
    color: #8a3ab9;
}

.fa-twitter:hover {
    color: #00acee;
}

.fa-linkedin-in:hover {
    color: #0e76a8;
}

.fa-youtube:hover {
    color: #c4302b;
}


/* ends */


/* Media Query */

@media screen and (max-width:575.98px) {
    .social ul li {
        margin: 1em 0.5em 0 0.5em;
    }
    .logoimg {
        width: 50%;
        margin: 1em 25%;
    }
}

@media screen and (min-width:576px) and (max-width:767.98px) {
    footer {
        text-align: justify;
        background-color: rgba(128, 128, 128, 0.212);
    }
    .footer .col-lg-4 {
        padding: 1em;
    }
    .logoimg {
        width: 40%;
        margin: 1em 30%;
    }
    .services h2,
    .contact h2 {
        padding-top: 1em;
        font-size: 30px;
        letter-spacing: 1px;
    }
    .services ul li {
        padding-top: 1.5em;
        font-weight: bold;
    }
    .contact ul li {
        padding-top: 1.5em;
        font-weight: bold;
    }
    .services ul li a:hover {
        letter-spacing: 0.01em;
    }
    .contact ul li span i {
        margin-right: 0.8em;
        padding-top: 0.5em;
        font-size: 1.5em;
    }
    .contact ul li span .fa-map-marker-alt {
        padding-top: 1em;
    }
    .social ul li {
        display: inline-block;
        font-size: 1.5em;
        margin: 1em 1em 0 1em;
    }
    .quotep1 {
        width: 40%;
        margin: auto;
    }
    .copy {
        padding: 0.5em 0;
        border-top: 0.1em solid rgb(255, 255, 255, 0.3);
    }
    /* Social Media Icons on Hover */
    .fa-facebook-f:hover {
        color: #4267b2;
    }
    .fa-instagram:hover {
        color: #8a3ab9;
    }
    .fa-twitter:hover {
        color: #00acee;
    }
    .fa-linkedin-in:hover {
        color: #0e76a8;
    }
    .fa-youtube:hover {
        color: #c4302b;
    }
}

@media screen and (min-width:992px) and (max-width:1199.98px) {
    footer {
        text-align: left;
    }
    .footer .col-lg-4 {
        padding: 1em;
    }
    .logoimg {
        width: 50%;
        margin: 1em 25%;
    }
    .services h2,
    .contact h2 {
        padding-top: 1em;
        font-size: 24px;
        letter-spacing: 1px;
    }
    .services ul li {
        padding-top: 1em;
        font-weight: bold;
    }
    .contact ul li {
        padding-top: 1em;
        font-weight: bold;
    }
    .contact ul li span i {
        margin-left: -1.2em;
        margin-right: 0.5em;
        padding-top: 1em;
        font-size: 1em;
    }
    .contact ul li span .fa-map-marker-alt {
        padding-top: 2em;
    }
    .social ul li {
        display: inline-block;
        font-size: 1.5em;
        margin: 1em 1em 0 1em;
    }
    .quotep1 {
        width: 60%;
        margin: auto;
    }
}

@media screen and (min-width:1200px) and (max-width:1599.98px) {}


/* Ends */
    .main {
        padding: 1em 0;
        margin-bottom: 1em;
    }
    
    .pixxmo {
        color: #66fcf1;
    }
    
    .main .home1 {
        width: 60%;
        margin: 4em 0% 0 40%;
        padding: 1em;
    }
    
    .main .home1 p {
        text-align: justify;
    }
    
    .homeimg img {
        width: 65%;
        margin: 1em auto 0 auto;
        -webkit-filter: drop-shadow(1px 0px 0px white);
                filter: drop-shadow(1px 0px 0px white);
    }
    
    .home1 h1 {
        font-size: 45px;
    }
    
    .home1 h1 span {
        font-weight: bold;
    }
    
    .quotep {
        width: 40%;
        font-weight: bold;
    }
    
    .home1 .quotelink1 p,
    .quotelink p {
        margin: 1em 0 0 0;
        padding: 1em 0;
        border-radius: 1em;
        border: 0.2em solid #66fcf1;
        text-align: center;
    }
    
    .home1 .quotelink1 p:hover,
    .quotelink p:hover {
        border: 0.2em solid white;
        background-color: #1f2833;
    }
    
    .service,
    .client,
    .diff,
    .beni,
    .certi {
        margin: 1em auto;
        text-align: center;
    }
    /* Service, Industry, and Diff Section */
    
    #service,
    #industry {
        margin-bottom: 3em;
    }
    
    .service .row,
    .industry .row {
        width: 100%;
        margin: auto;
    }
    
    .service .col-lg-4 .box {
        margin: 1em auto;
        width: 70%;
        border-radius: 0.5em;
        padding: 1em 1em 0.5em 1em;
        border: 0.1em solid #66fcf1;
    }
    
    .service .col-lg-4 .boximg img {
        width: 25%;
        margin: 1em auto;
    }
    
    .industry .col-lg-3 .inbox {
        margin: 1em 15%;
        border-radius: 0.5em;
        padding: 1em 1em 0.5em 1em;
        background-color: #45a29e;
        width: 70%;
        box-shadow: 0.1em 0.1em 0.5em rgba(255, 255, 255, 0.726);
    }
    
    .industry .col-lg-3 .inboximg i {
        font-size: 2em;
        color: white;
    }
    
    .industry .col-lg-3 .inboxtxt p {
        font-size: 1.5em;
        text-transform: uppercase;
    }
    
    .diff .col-lg-6 .inbox {
        margin: 1em 15%;
        border-radius: 0.5em;
        padding: 1em 1em 0.5em 1em;
        background-color: #45a29e;
        width: 70%;
        font-size: 2em;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }
    /* Ends */
    /* Client Section */
    
    .client {
        padding: 1em 0;
        text-transform: uppercase;
    }
    
    .client ul li {
        display: inline-block;
        margin: 2em 2em;
    }
    
    .client .clientlogo img {
        width: 150px;
        opacity: 1;
        -webkit-filter: drop-shadow(0.1em 0px 0px rgba(230, 230, 230, 0.829));
                filter: drop-shadow(0.1em 0px 0px rgba(230, 230, 230, 0.829));
    }
    
    .marquee {
        position: relative;
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
    
    .track {
        position: absolute;
        white-space: nowrap;
        will-change: transform;
        -webkit-animation: marquee 32s linear infinite;
                animation: marquee 32s linear infinite;
    }
    
    @-webkit-keyframes marquee {
        from {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
        }
        to {
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
        }
    }
    
    @keyframes marquee {
        from {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
        }
        to {
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
        }
    }
    /* Ends */
    /* One Stop Solution */
    
    .dm h1,
    .one h1,
    .how h1,
    .certi h1,
    .service h1,
    .client h1,
    .diff h1,
    .beni h1,
    .industry h1 {
        text-align: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0em auto;
        border-bottom: 0.1em solid rgba(255, 255, 255, 0);
    }
    
    .dm h1:hover,
    .one h1:hover,
    .how h1:hover,
    .service h1:hover,
    .client h1:hover,
    .diff h1:hover,
    .beni h1:hover,
    .certi h1:hover,
    .industry h1:hover {
        color: #66fcf1;
        border-bottom: 0.1em solid #66fcf1;
    }
    
    .dm .col-lg-4 p {
        width: 60%;
        margin: 1em 20%;
        padding: 2em 0;
        text-align: center;
        background-color: rgba(128, 128, 128, 0.212);
        border-radius: 0.5em;
        border: 0.1em solid rgba(128, 128, 128, 0.75);
        box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
    }
    
    .one p {
        text-align: justify;
    }
    
    .one .row .col-lg-4 span {
        text-align: center;
        font-weight: bold;
        padding: 0.5em 2em;
        margin: 1em auto;
        /* text-transform: uppercase; */
        color: #66fcf1;
        font-size: 1.2em;
    }
    /* Ends */
    /* How we work */
    
    .howstep ul li {
        padding: 1em 1em;
        border: 0.1em solid #45a29e;
        border-radius: 0.3em;
        margin: 2em;
    }
    
    .howstep ul li p strong {
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #66fcf1;
        font-size: 20px;
    }
    
    .howstep ul li span {
        margin: 0 1em 0 0em;
        border-right: 0.2em solid rgba(128, 128, 128, 0.75);
        width: 5%;
    }
    
    .howstep ul li span i {
        padding: 0.5em 0.5em 0.5em 0.5em;
        font-size: 3em;
    }
    
    .howstep ul li span .fa-lightbulb {
        margin-left: 0.15em;
    }
    
    .howstep ul li p {
        margin-top: 1em;
    }
    /* Ends */
    /* Benefits */
    
    .beni .col-lg-3 .inbox {
        margin: 1em 0;
        border-radius: 0.5em;
        padding: 2em 1em 1em 1em;
        background-color: #45a29e;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        box-shadow: inset 0em 0em 0.25em 0.1em rgba(7, 7, 7, 0.692);
    }
    /* Ends */
    /* Contact Section */
    
    .contacthome {
        padding: 1em 0 2em 0;
    }
    
    .contacttxt ul li {
        margin-top: 1em;
        font-size: 1.5em;
    }
    
    .contacttxt {
        width: 60%;
        margin: 1em 40%;
    }
    
    .contacttxt h1 {
        font-size: 42px;
        width: 80%;
    }
    
    .contacttxt .contdetails {
        width: 90%;
        margin: 1em 0;
        padding: 1em 0;
        background-color: rgba(128, 128, 128, 0.212);
        border-radius: 0.5em;
        border: 0.1em solid rgba(128, 128, 128, 0.75);
        box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
    }
    
    .contacttxt .contdetails span {
        margin: 0 1em 0 1em;
        border-right: 0.15em solid rgba(128, 128, 128, 0.75);
    }
    
    .contacttxt .contdetails span i {
        padding: 0.5em 0.5em 0 0.5em;
        font-size: 2em;
    }
    
    .fa-fire {
        margin-right: 0.5em;
        padding: 0.25em 0;
        opacity: 1;
        color: #66fcf1;
    }
    /* Ends */
    /* Media Query */
    
    @media screen and (max-width:575.98px) {
        .main {
            padding: 0em 0;
            margin-bottom: 1em;
        }
        .main .home1 {
            width: 100%;
            margin: 0em 0% 0 0%;
            padding: 0em;
        }
        .quotep {
            width: 80%;
            margin: auto;
        }
        .homeimg img {
            width: 100%;
            margin: 0em auto 0 auto;
            -webkit-filter: drop-shadow(1px 0px 0px white);
                    filter: drop-shadow(1px 0px 0px white);
        }
        .dm p {
            text-align: justify;
        }
        .dm .col-lg-4 p {
            width: 80%;
            margin: 1em 10%;
            padding: 2em 0;
            text-align: center;
            background-color: rgba(128, 128, 128, 0.212);
            border-radius: 0.5em;
            border: 0.1em solid rgba(128, 128, 128, 0.75);
            box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
        }
        .service .col-lg-4 .box {
            margin: 1em auto;
            width: 100%;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em auto;
            width: 100%;
        }
        .one .row .col-lg-4 span {
            padding: 0em 0em;
            margin: 0em auto;
        }
        .howstep ul li {
            margin: 1em;
        }
        .howstep ul li span {
            width: 50%;
        }
        .howstep ul li span i {
            padding: 1em 0.5em 0.5em 0.5em;
        }
        .howstep ul li span .fa-lightbulb {
            margin-left: 0.2em;
        }
        .howstep ul li span .fa-sketch {
            margin-left: -0.15em;
            margin-top: 1em;
        }
        .howstep ul li span .fa-connectdevelop {
            margin-left: -0.25em;
            margin-top: 1em;
        }
        .howstep ul li span .fa-servicestack,
        .fa-check-circle {
            margin-left: -0.05em;
            margin-top: 1em;
        }
        .howstep ul li span .fa-check-circle {
            margin-left: -0.05em;
            margin-top: 0.85em;
        }
        .beni .col-lg-3 .inbox {
            margin: 0.5em 0;
        }
        .diff .col-lg-6 .inbox {
            margin: 0.5em 0%;
            border-radius: 0.5em;
            padding: 1em 1em 0.5em 1em;
            background-color: #45a29e;
            width: 100%;
            font-size: 2em;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }
        .contacttxt p {
            text-align: center;
        }
        .contacttxt ul li {
            margin-top: 0.5em;
            font-size: 1.5em;
        }
        .contacttxt {
            width: 100%;
            margin: 1em 0%;
        }
        .contacttxt h1 {
            text-align: center;
            font-size: 42px;
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
        }
        .contacttxt .contdetails span {
            margin: 0 1em 0 0em;
        }
        .contacttxt .contdetails span i {
            padding: 1em 0.5em 0 0.5em;
            font-size: 2em;
        }
    }
    
    @media screen and (min-width:576px) and (max-width:767.98px) {
        .main {
            padding: 0em 0;
        }
        .main .home1 {
            width: 95%;
            margin: 0em auto;
        }
        .homeimg img {
            width: 100%;
        }
        .quotep {
            width: 60%;
            margin: auto 0;
        }
        .service .col-lg-4 .box {
            width: 100%;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .diff .col-lg-6 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .marquee {
            width: 95%;
            margin: auto;
        }
        .dm .col-lg-4 p {
            width: 80%;
            margin: 1em auto;
        }
        .one {
            width: 95%;
            margin: auto;
        }
        .one .row .col-lg-4 span {
            padding: 0.5em 0em;
            margin: 0em auto;
        }
        .howstep ul li span {
            width: 25%;
        }
        .howstep ul li span i {
            padding: 0.5em 0.5em 0.5em 0.5em;
            font-size: 3em;
        }
        .howstep ul li span .fa-lightbulb {
            margin-left: 0.15em;
        }
        .beni .col-lg-3 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .contacttxt {
            width: 100%;
            margin: 1em auto;
        }
        .contacttxt p {
            text-align: center;
        }
        .contacttxt h1 {
            font-size: 42px;
            text-align: center;
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
            margin: 1em auto;
        }
    }
    
    @media screen and (min-width:768px) and (max-width:991.98px) {
        .main {
            padding: 0em 0;
        }
        .main .home1 {
            width: 95%;
            margin: 0em auto;
        }
        .homeimg img {
            width: 100%;
        }
        .quotep {
            width: 60%;
            margin: auto 0;
        }
        .service .col-lg-4 .box {
            width: 100%;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .diff .col-lg-6 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .marquee {
            width: 95%;
            margin: auto;
        }
        .dm .col-lg-4 p {
            width: 80%;
            margin: 1em auto;
        }
        .one {
            width: 95%;
            margin: auto;
        }
        .one .row .col-lg-4 span {
            padding: 0.5em 0em;
            margin: 0em auto;
        }
        .howstep ul li span {
            width: 25%;
        }
        .howstep ul li span i {
            padding: 0.5em 0.5em 0.5em 0.5em;
            font-size: 3em;
        }
        .howstep ul li span .fa-lightbulb {
            margin-left: 0.15em;
        }
        .beni .col-lg-3 .inbox {
            margin: 1em auto;
            width: 100%;
        }
        .contacttxt {
            width: 100%;
            margin: 1em auto;
        }
        .contacttxt p {
            text-align: center;
        }
        .contacttxt h1 {
            font-size: 42px;
            text-align: center;
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
            margin: 1em auto;
        }
    }
    
    @media screen and (min-width:992px) and (max-width:1199.98px) {
        .main {
            padding: 0em 0;
            margin-bottom: 1em;
        }
        .main .home1 {
            width: 100%;
            margin: 4em 0% 0 0%;
            padding: 1em;
        }
        .homeimg img {
            width: 100%;
            margin: 5em auto 0 auto;
        }
        .quotep {
            width: 60%;
        }
        .service .col-lg-4 .box {
            margin: 1em auto;
            width: 100%;
        }
        .service .col-lg-4 .boximg img {
            width: 15%;
            margin: 1em auto;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em 0%;
            width: 100%;
        }
        .diff .col-lg-6 .inbox {
            margin: 1em auto;
            width: 90%;
        }
        .dm .col-lg-4 p {
            width: 100%;
            margin: 1em 0%;
            padding: 2em 0;
            text-align: center;
            background-color: rgba(128, 128, 128, 0.212);
            border-radius: 0.5em;
            border: 0.1em solid rgba(128, 128, 128, 0.75);
            box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
        }
        .one p {
            text-align: left;
        }
        .howstep ul li {
            margin: 2em auto;
        }
        .howstep ul li span {
            width: 10%;
        }
        .contacttxt {
            width: 100%;
            margin: 1em 0%;
        }
        .contacttxt h1 {
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
        }
    }
    
    @media screen and (min-width:1200px) and (max-width:1599.98px) {
        .main {
            padding: 0em 0;
            margin-bottom: 1em;
        }
        .main .home1 {
            width: 100%;
            margin: 4em 0% 0 0%;
            padding: 1em;
        }
        .homeimg img {
            width: 80%;
            margin: 5em auto 0 auto;
        }
        .quotep {
            width: 60%;
        }
        .service .col-lg-4 .box {
            margin: 1em auto;
            width: 100%;
        }
        .service .col-lg-4 .boximg img {
            width: 15%;
            margin: 1em auto;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em 0%;
            width: 100%;
        }
        .diff .col-lg-6 .inbox {
            margin: 1em auto;
            width: 90%;
        }
        .dm .col-lg-4 p {
            width: 100%;
            margin: 1em 0%;
            padding: 2em 0;
            text-align: center;
            background-color: rgba(128, 128, 128, 0.212);
            border-radius: 0.5em;
            border: 0.1em solid rgba(128, 128, 128, 0.75);
            box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
        }
        .one p {
            text-align: left;
        }
        .howstep ul li {
            margin: 2em auto;
        }
        .howstep ul li span {
            width: 10%;
        }
        .contacttxt {
            width: 100%;
            margin: 1em 0%;
        }
        .contacttxt h1 {
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
        }
    }
    /* Ends */
/* contact form */

.contactform {
    background-color: rgba(128, 128, 128, 0.212);
    width: 70%;
    margin: 6% 0;
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: inset 0em 0em 1em 0.1em rgba(255, 255, 255, 0.349);
}

.contactform form label {
    font-size: 20px;
}

.contactform form input,
.contactform form textarea {
    background-color: transparent;
    border: none;
    color: white;
    border-bottom: 0.1em solid rgb(255, 255, 255);
}

.contactform form input:focus,
.contactform form textarea:focus {
    border: none !important;
    outline: none !important;
    background-color: rgb(240, 248, 255);
}

.contactform form .subbtn {
    width: 50%;
    padding: 1em 0;
    font-size: 16px;
    border-radius: 1em;
    margin: 1em 25%;
}

.msg {
    color: #66fcf1;
    display: none;
    padding: 0.1rem 0;
    text-align: center;
}

.msgAppear {
    display: block;
}


/* ends */


/* Media Query */

@media screen and (max-width: 575.98px) {
    .contactform {
        width: 100%;
    }
    .contactform form .subbtn {
        width: 60%;
        padding: 1em 0;
        font-size: 16px;
        border-radius: 1em;
        margin: 1em 20%;
    }
    .contactform form label {
        margin: 1em 0 0 0;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
    .contactform {
        width: 100%;
    }
    .contactform form .subbtn {
        width: 60%;
        padding: 1em 0;
        font-size: 16px;
        border-radius: 1em;
        margin: 1em 20%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .contactform {
        width: 100%;
    }
    .contactform form .subbtn {
        width: 60%;
        padding: 1em 0;
        font-size: 16px;
        border-radius: 1em;
        margin: 1em 20%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
    .contactform {
        width: 80%;
    }
    .contactform form .subbtn {
        width: 60%;
        padding: 1em 0;
        font-size: 16px;
        border-radius: 1em;
        margin: 1em 20%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .contactform {
        width: 80%;
        text-align: left;
    }
    .contactform form .subbtn {
        width: 60%;
        padding: 1em 0;
        font-size: 16px;
        border-radius: 1em;
        margin: 1em 20%;
    }
}


/* Ends */
.about {
    margin: 2em 0;
    text-align: justify;
}

.about .row {
    margin-bottom: 1em;
}

.about-img img {
    width: 90%;
    margin: auto 25%;
    -webkit-filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
            filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
}

.about-txt {
    width: 60%;
    margin: 5% 20%;
}

.abouttxt {
    margin-bottom: 2em;
}

.abouttxt h1,
.abouttxt h3 {
    font-weight: bold;
    color: #66fcf1;
}

.abouttxt .row .col-lg-12 .box {
    border: 0.1em solid #66fcf1;
    margin: 1em 0;
    padding: 1em 2em;
    background-color: rgba(128, 128, 128, 0.25);
    border-radius: 0.5em;
    box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.418);
}


/* Media Query */

@media screen and (max-width: 575.98px) {
    .about {
        margin: 0em 0;
        text-align: left;
    }
    .about-img img {
        width: 100%;
        margin: auto;
        -webkit-filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
                filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
    }
    .about-txt {
        width: 100%;
        margin: 5% auto;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
    .about {
        margin: 0em 0;
        text-align: left;
    }
    .about-img img {
        width: 100%;
        margin: auto;
        -webkit-filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
                filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
    }
    .about-txt {
        width: 100%;
        margin: 5% auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .about {
        margin: 0em 0;
        text-align: left;
    }
    .about-img img {
        width: 100%;
        margin: auto;
        -webkit-filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
                filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
    }
    .about-txt {
        width: 100%;
        margin: 5% auto;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {}

@media screen and (min-width: 1200px) and (max-width: 1599.98px) {}


/* Ends */
/* .contactus {
    background-color: green;
} */

.contactus h1 {
    text-align: center;
}

.contactus h4 {
    text-align: center;
}

.contactfrm h1 {
    margin: 0 0 0.5em 0;
    letter-spacing: 0.05em;
}

.mcontact {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 0.5em 0;
}

.mcontact h1 {
    font-size: 50px;
    margin: 0.5em 0;
}


/* Contact Form */

.contactfrm {
    background: url(/static/media/map-image.f40ed5b1.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.contactfrm .contacttxt {
    padding: 0.5em;
    margin-top: 4em;
}

.contactfrm .contdetails {
    margin: 3em 0 !important;
}

.contacttxt .contdetails span .fa-map-marker-alt {
    padding: 0.6em 0.5em 0 0.5em;
    font-size: 2.5em;
}


/* ends */


/* Google Map */

.map {
    margin: 0.5em 0;
    padding: 1em 0;
}

.map iframe {
    border-radius: 1em;
    border: 0;
    width: 80%;
    height: 60vh;
    margin: auto 10%;
    box-shadow: 0em 0em 0.5em 0.1em rgb(184, 184, 184);
}


/* Ends */
.mainwork {
  margin: 1em 0;
}

.mainhead {
  text-align: center;
}

.mainhead h1 {
  font-size: 50px;
}

.mainhead h4 {
  width: 60%;
  margin: auto;
}

.mainbody .row {
  width: 100%;
  margin: 2em auto 3em auto;
}

.mainbody .col-lg-6 {
  padding: 1em auto;
  margin: 1em auto;
}

.mainbody .pcards {
  border-radius: 0.5em;
  width: 100%;
  height: 490px;
  position: relative;
}

.mainbody .pcards .pcardsimg {
  width: 100%;
  position: absolute;
  height: 490px;
  top: 0;
  left: 0;
}

.mainbody .pcards .pcardsimg img {
  width: 100%;
  height: 490px;
  border-radius: 1em;
  -webkit-filter: drop-shadow(0px 0px 5px #ffffff77);
          filter: drop-shadow(0px 0px 5px #ffffff77);
}

/* .services1 {
    background-color: green;
} */

.services1 h1 {
    font-size: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0em auto;
    border-bottom: 0.1em solid rgba(255, 255, 255, 0);
}

.services1 h1:hover {
    color: #66fcf1;
    border-bottom: 0.1em solid #66fcf1;
}

.services1 h2 {
    color: #66fcf1;
    text-align: center;
}

.services1 p {
    text-align: justify;
}

.services1 span {
    color: #66fcf1;
}

.btnColor {
    background-color: #66fcf1;
}


/* services list */

.lservices {
    text-align: center;
}

.lservices .row {
    width: 100%;
    margin: auto;
}

.lservices .col-lg-4 {
    padding: 1em;
}

.lservices .sbox {
    margin: 1em auto;
    width: 70%;
    border-radius: 0.5em;
    padding: 1em 1em 0.5em 1em;
    border: 0.1em solid #66fcf1;
}

.lservices .col-lg-4 .sboximg img {
    width: 25%;
    margin: 1em auto;
}

.lservices .col-lg-4 .sboxtxt {
    margin: 1em auto 2em auto;
    text-transform: uppercase;
}


/* ends */


/* Service Description */

.sdesc {
    margin-top: 2em;
}

.aboutservice {
    padding: 2em 2em;
    margin: 2em auto;
    background-color: rgba(128, 128, 128, 0.212);
    border-radius: 1em;
    border: 0.1em solid rgba(128, 128, 128, 0.75);
    box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
    width: 95%;
}

.aboutservice h3 {
    text-transform: uppercase;
    color: #66fcf1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}


/* ends */


/* Service Image */

.serviceimg img {
    width: 70%;
    margin: auto 30%;
    -webkit-filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
            filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
}

.workimg img {
    width: 70%;
    margin: auto 30%;
    -webkit-filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
            filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
}

.servicecontact h1,
.servicecontact h3 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0em auto;
}

.servicecontact span {
    color: #66fcf1;
}


/* Ends */


/* Media Query */

@media screen and (max-width: 575.98px) {
    .lservices .sbox {
        margin: 0.5em auto;
        width: 100%;
    }
    .sdesc {
        margin-top: 0em;
    }
    .aboutservice {
        padding: 2em 2em;
        margin: 1em auto;
        width: 100%;
    }
    .serviceimg img {
        width: 100%;
        margin: auto 0%;
    }
    .workimg img {
        width: 100%;
        margin: auto 0%;
    }
    .servicecontact h1,
    .servicecontact h3 {
        text-align: center;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .serviceimg img {
        width: 100%;
        margin: auto 0%;
    }
    .workimg img {
        width: 100%;
        margin: auto 0%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {}

@media screen and (min-width: 1200px) and (max-width: 1599.98px) {}


/* Ends */
.glitch-wrapper {
    margin: 10vh 0;
    text-align: center;
    -webkit-animation: glitch 3s linear infinite;
            animation: glitch 3s linear infinite;
}

.glitch-text {
    font-weight: bold;
}

.glitch-text::before {
    content: 'ERROR 404';
    color: white;
    -webkit-filter: blur(7px);
    -moz-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    display: block;
    position: absolute;
    z-index: -1;
    font-size: 100px;
    left: 0px;
    right: 0px;
    margin-top: -40px;
    opacity: 0.5;
}

.glitch-text {
    position: relative;
    z-index: 2;
    opacity: .9;
    margin: 0 auto;
    text-shadow: 0px 0px 3px white;
    -webkit-animation: glitch 3s infinite;
            animation: glitch 3s infinite;
    cursor: pointer;
    font-size: 50px;
    color: white;
}


/* JUST RANDOM VALUES HERE! */

@-webkit-keyframes glitch {
    3% {
        text-shadow: 7px 7px 0px red;
    }
    6% {
        text-shadow: -9px 4px 0px blue;
        -webkit-transform: translate(4px, -10px) skewX(3240deg);
                transform: translate(4px, -10px) skewX(3240deg);
    }
    7% {
        text-shadow: 3px -4px 0px green;
    }
    9% {
        text-shadow: 0px 0px 3px white;
        -webkit-transform: translate(0px, 0px) skewX(0deg);
                transform: translate(0px, 0px) skewX(0deg);
    }
    2%,
    54% {
        -webkit-transform: translateX(0px) skew(0deg);
                transform: translateX(0px) skew(0deg);
    }
    55% {
        -webkit-transform: translate(-2px, 6px) skew(-5530deg);
                transform: translate(-2px, 6px) skew(-5530deg);
    }
    56% {
        text-shadow: 0px 0px 3px white;
        -webkit-transform: translate(0px, 0px) skew(0deg);
                transform: translate(0px, 0px) skew(0deg);
    }
    57% {
        text-shadow: 7px 7px 0px red;
        -webkit-transform: translate(4px, -10px) skew(-70deg);
                transform: translate(4px, -10px) skew(-70deg);
    }
    58% {
        text-shadow: 0px 0px 3px white;
        -webkit-transform: translate(0px, 0px) skew(0deg);
                transform: translate(0px, 0px) skew(0deg);
    }
    62% {
        text-shadow: 3px -4px 0px green;
        -webkit-transform: translate(0px, 20px) skew(0deg);
                transform: translate(0px, 20px) skew(0deg);
    }
    63% {
        text-shadow: 0px 0px 3px white;
        -webkit-transform: translate(4px, -2px) skew(0deg);
                transform: translate(4px, -2px) skew(0deg);
    }
    64% {
        -webkit-transform: translate(1px, 3px) skew(-230deg);
                transform: translate(1px, 3px) skew(-230deg);
    }
    65% {
        -webkit-transform: translate(-7px, 2px) skew(120deg);
                transform: translate(-7px, 2px) skew(120deg);
    }
    66% {
        -webkit-transform: translate(0px, 0px) skew(0deg);
                transform: translate(0px, 0px) skew(0deg);
    }
}

@keyframes glitch {
    3% {
        text-shadow: 7px 7px 0px red;
    }
    6% {
        text-shadow: -9px 4px 0px blue;
        -webkit-transform: translate(4px, -10px) skewX(3240deg);
                transform: translate(4px, -10px) skewX(3240deg);
    }
    7% {
        text-shadow: 3px -4px 0px green;
    }
    9% {
        text-shadow: 0px 0px 3px white;
        -webkit-transform: translate(0px, 0px) skewX(0deg);
                transform: translate(0px, 0px) skewX(0deg);
    }
    2%,
    54% {
        -webkit-transform: translateX(0px) skew(0deg);
                transform: translateX(0px) skew(0deg);
    }
    55% {
        -webkit-transform: translate(-2px, 6px) skew(-5530deg);
                transform: translate(-2px, 6px) skew(-5530deg);
    }
    56% {
        text-shadow: 0px 0px 3px white;
        -webkit-transform: translate(0px, 0px) skew(0deg);
                transform: translate(0px, 0px) skew(0deg);
    }
    57% {
        text-shadow: 7px 7px 0px red;
        -webkit-transform: translate(4px, -10px) skew(-70deg);
                transform: translate(4px, -10px) skew(-70deg);
    }
    58% {
        text-shadow: 0px 0px 3px white;
        -webkit-transform: translate(0px, 0px) skew(0deg);
                transform: translate(0px, 0px) skew(0deg);
    }
    62% {
        text-shadow: 3px -4px 0px green;
        -webkit-transform: translate(0px, 20px) skew(0deg);
                transform: translate(0px, 20px) skew(0deg);
    }
    63% {
        text-shadow: 0px 0px 3px white;
        -webkit-transform: translate(4px, -2px) skew(0deg);
                transform: translate(4px, -2px) skew(0deg);
    }
    64% {
        -webkit-transform: translate(1px, 3px) skew(-230deg);
                transform: translate(1px, 3px) skew(-230deg);
    }
    65% {
        -webkit-transform: translate(-7px, 2px) skew(120deg);
                transform: translate(-7px, 2px) skew(120deg);
    }
    66% {
        -webkit-transform: translate(0px, 0px) skew(0deg);
                transform: translate(0px, 0px) skew(0deg);
    }
}
/* FAQ */

.faq-header {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 1em 0;
}

.faq-content {
    margin: 1em auto 3em auto;
    width: 80%;
}

.faq-question {
    padding: 20px 0;
    border-bottom: 1px solid white;
    width: 80%;
    margin: 0 auto;
}

.panel-title {
    font-size: 24px;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 10px 10px 0 50px;
    display: block;
    cursor: pointer;
    margin-left: 1em;
}

.panel-content {
    font-size: 20px;
    margin: 0 60px;
    height: 0;
    overflow: hidden;
    z-index: 1;
    position: relative;
    opacity: 0;
    transition: 0.4s ease;
    text-align: justify;
}

.panel-content ul li {
    list-style: disc !important;
    margin-left: 1em;
    padding-left: 0.5em;
}

.panel:checked~.panel-content {
    height: auto;
    opacity: 1;
    padding: 14px;
}

.plus {
    position: absolute;
    margin-left: 20px;
    margin-top: 4px;
    z-index: 5;
    font-size: 42px;
    line-height: 100%;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    transition: 0.2s ease;
}

.panel:checked~.plus {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.panel {
    display: none;
}


/* Ends */


/* Privacy Policy and Terms & Conditions */

.extra {
    width: 95%;
    margin: 0 auto;
}

.extra h1 {
    font-weight: bold;
    margin: 0 auto;
    border-bottom: 0.1em solid #66fcf200;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.extra h1:hover {
    color: #66fcf1;
    border-bottom: 0.1em solid #66fcf1;
}

.extra h5 {
    font-weight: bold;
}


/* Ends */


/* Media Query */

@media screen and (max-width:575.98px) {
    .faq-content {
        margin: 0em auto 2em auto;
        width: 100%;
    }
    .faq-question {
        padding: 5px 0;
        width: 100%;
    }
    .panel-title {
        font-size: 20px;
        width: 100%;
        position: relative;
        margin: 0;
        padding: 5px 5px 0 40px;
        display: block;
        cursor: pointer;
        margin-left: 0.5em;
    }
    .panel-content {
        font-size: 18px;
        margin: 0 0px;
        text-align: left;
    }
    .plus {
        margin-left: 10px;
    }
}


/* @media screen and (min-width:576px) and (max-width:767.98px) {}

@media screen and (min-width:768px) and (max-width:991.98px) {}

@media screen and (min-width:992px) and (max-width:1199.98px) {}

@media screen and (min-width:1200px) and (max-width:1599.98px) {} */


/* Ends */
.seo h1 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0em auto;
    border-bottom: 0.1em solid rgba(255, 255, 255, 0);
    text-align: center;
}

.seo h1:hover {
    color: #66fcf1;
    border-bottom: 0.1em solid #66fcf1;
}

.seo h2 {
    color: #66fcf1;
    margin: 1em 0 0.5em 0;
    font-weight: bold;
}

.seo span,
.seo p {
    padding: 0 2em;
    width: 100%;
}

.seo .col-lg-3 .seobox {
    padding: 1em;
    border: 0.1em solid #45a29e;
    border-radius: 0.5em;
    margin: 1em auto;
    text-align: center;
    background-color: #45a29e;
}

.seo .col-lg-4 .seobox {
    padding: 1em;
    border: 0.1em solid #45a29e;
    border-radius: 0.5em;
    margin: 1em auto;
    text-align: center;
    width: 80%;
    background-color: #45a29e;
}

.seo .col-lg-6 .seobox {
    padding: 1em;
    border: 0.1em solid #45a29e;
    border-radius: 0.5em;
    margin: 1em 25%;
    text-align: center;
    background-color: #45a29e;
    width: 50%;
}


/* Media Query */

@media screen and (max-width:575.98px) {
    .seo .col-lg-6 .seobox {
        margin: 1em 0%;
        width: 100%;
    }
}

@media screen and (min-width:576px) and (max-width:767.98px) {}

@media screen and (min-width:768px) and (max-width:991.98px) {}

@media screen and (min-width:992px) and (max-width:1199.98px) {}

@media screen and (min-width:1200px) and (max-width:1599.98px) {}


/* Ends */
/* .sitemap {
    background-color: red;
} */

.sitemap h1 {
    font-size: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0em auto;
    border-bottom: 0.1em solid rgba(255, 255, 255, 0);
}

.sitemap h1:hover {
    color: #66fcf1;
    border-bottom: 0.1em solid #66fcf1;
}

.sitebox h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.sitebox {
    width: 100%;
    margin: 0 auto;
    padding: 1em 2em;
}

.sitebox ul li {
    padding: 0.5em 0;
}

.sitebox ul li i {
    font-size: 1.2em;
}

.sitecontact li i {
    font-size: 1.5em !important;
    margin: 0.5em 1em 0 0;
    color: #66fcf1;
}

.sitecontact .fa-map-marker-alt {
    margin: 1.5em 1em 0 0;
}
* {
    margin: 0%;
    padding: 0%;
    list-style: none;
    box-sizing: border-box;
}


/* scrollbar */

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.2em;
    display: none;
}

::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 1em;
}


/* ends */


/* Wrap Content and Footer */

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.content {
    flex-grow: 1;
}

.content .container-fluid {
    width: 95%;
    margin: auto;
}


/* ends */

body {
    margin: 0%;
    padding: 0%;
    color: white;
    background-color: #0b0c10;
}

.row {
    width: 100%;
    margin: auto;
}

a {
    text-decoration: none;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
}

a:hover {
    text-decoration: none;
    color: #66fcf1;
    font-weight: bold;
}


/* font size and style */

h1 {
    font-size: 40px;
    font-family: "Crimson Text", serif;
}

h2 {
    font-size: 28px;
    font-family: "Crimson Text", serif;
}

h3 {
    font-size: 24px;
    font-family: "Crimson Text", serif;
}

h4 {
    font-size: 20px;
    font-family: "Crimson Text", serif;
}

h5 {
    font-size: 18px;
    font-family: "Crimson Text", serif;
}

p {
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
}


/* Ends */


/* Background Video */

.overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    background-color: rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    overflow: hidden;
}

.bgvideo img {
    position: fixed;
    object-fit: cover;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: -2;
}


/* ends */


/* Go To Top */

.gototop {
    position: fixed;
    bottom: 100px;
    right: 200px;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}

.gototop.active {
    opacity: 1;
    visibility: visible;
}

.gototop a {
    width: 50px;
    height: 50px;
    display: table;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 50%;
}

.gototop a i {
    height: 50px;
    display: table-cell;
    vertical-align: middle;
}

.gototop a:hover,
.gototop a:active,
.gototop a:focus {
    text-decoration: none;
    outline: none;
}


/* ends */
