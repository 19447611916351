    .main {
        padding: 1em 0;
        margin-bottom: 1em;
    }
    
    .pixxmo {
        color: #66fcf1;
    }
    
    .main .home1 {
        width: 60%;
        margin: 4em 0% 0 40%;
        padding: 1em;
    }
    
    .main .home1 p {
        text-align: justify;
    }
    
    .homeimg img {
        width: 65%;
        margin: 1em auto 0 auto;
        filter: drop-shadow(1px 0px 0px white);
    }
    
    .home1 h1 {
        font-size: 45px;
    }
    
    .home1 h1 span {
        font-weight: bold;
    }
    
    .quotep {
        width: 40%;
        font-weight: bold;
    }
    
    .home1 .quotelink1 p,
    .quotelink p {
        margin: 1em 0 0 0;
        padding: 1em 0;
        border-radius: 1em;
        border: 0.2em solid #66fcf1;
        text-align: center;
    }
    
    .home1 .quotelink1 p:hover,
    .quotelink p:hover {
        border: 0.2em solid white;
        background-color: #1f2833;
    }
    
    .service,
    .client,
    .diff,
    .beni,
    .certi {
        margin: 1em auto;
        text-align: center;
    }
    /* Service, Industry, and Diff Section */
    
    #service,
    #industry {
        margin-bottom: 3em;
    }
    
    .service .row,
    .industry .row {
        width: 100%;
        margin: auto;
    }
    
    .service .col-lg-4 .box {
        margin: 1em auto;
        width: 70%;
        border-radius: 0.5em;
        padding: 1em 1em 0.5em 1em;
        border: 0.1em solid #66fcf1;
    }
    
    .service .col-lg-4 .boximg img {
        width: 25%;
        margin: 1em auto;
    }
    
    .industry .col-lg-3 .inbox {
        margin: 1em 15%;
        border-radius: 0.5em;
        padding: 1em 1em 0.5em 1em;
        background-color: #45a29e;
        width: 70%;
        box-shadow: 0.1em 0.1em 0.5em rgba(255, 255, 255, 0.726);
    }
    
    .industry .col-lg-3 .inboximg i {
        font-size: 2em;
        color: white;
    }
    
    .industry .col-lg-3 .inboxtxt p {
        font-size: 1.5em;
        text-transform: uppercase;
    }
    
    .diff .col-lg-6 .inbox {
        margin: 1em 15%;
        border-radius: 0.5em;
        padding: 1em 1em 0.5em 1em;
        background-color: #45a29e;
        width: 70%;
        font-size: 2em;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }
    /* Ends */
    /* Client Section */
    
    .client {
        padding: 1em 0;
        text-transform: uppercase;
    }
    
    .client ul li {
        display: inline-block;
        margin: 2em 2em;
    }
    
    .client .clientlogo img {
        width: 150px;
        opacity: 1;
        filter: drop-shadow(0.1em 0px 0px rgba(230, 230, 230, 0.829));
    }
    
    .marquee {
        position: relative;
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
    
    .track {
        position: absolute;
        white-space: nowrap;
        will-change: transform;
        animation: marquee 32s linear infinite;
    }
    
    @keyframes marquee {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-50%);
        }
    }
    /* Ends */
    /* One Stop Solution */
    
    .dm h1,
    .one h1,
    .how h1,
    .certi h1,
    .service h1,
    .client h1,
    .diff h1,
    .beni h1,
    .industry h1 {
        text-align: center;
        width: fit-content;
        margin: 0em auto;
        border-bottom: 0.1em solid rgba(255, 255, 255, 0);
    }
    
    .dm h1:hover,
    .one h1:hover,
    .how h1:hover,
    .service h1:hover,
    .client h1:hover,
    .diff h1:hover,
    .beni h1:hover,
    .certi h1:hover,
    .industry h1:hover {
        color: #66fcf1;
        border-bottom: 0.1em solid #66fcf1;
    }
    
    .dm .col-lg-4 p {
        width: 60%;
        margin: 1em 20%;
        padding: 2em 0;
        text-align: center;
        background-color: rgba(128, 128, 128, 0.212);
        border-radius: 0.5em;
        border: 0.1em solid rgba(128, 128, 128, 0.75);
        box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
    }
    
    .one p {
        text-align: justify;
    }
    
    .one .row .col-lg-4 span {
        text-align: center;
        font-weight: bold;
        padding: 0.5em 2em;
        margin: 1em auto;
        /* text-transform: uppercase; */
        color: #66fcf1;
        font-size: 1.2em;
    }
    /* Ends */
    /* How we work */
    
    .howstep ul li {
        padding: 1em 1em;
        border: 0.1em solid #45a29e;
        border-radius: 0.3em;
        margin: 2em;
    }
    
    .howstep ul li p strong {
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #66fcf1;
        font-size: 20px;
    }
    
    .howstep ul li span {
        margin: 0 1em 0 0em;
        border-right: 0.2em solid rgba(128, 128, 128, 0.75);
        width: 5%;
    }
    
    .howstep ul li span i {
        padding: 0.5em 0.5em 0.5em 0.5em;
        font-size: 3em;
    }
    
    .howstep ul li span .fa-lightbulb {
        margin-left: 0.15em;
    }
    
    .howstep ul li p {
        margin-top: 1em;
    }
    /* Ends */
    /* Benefits */
    
    .beni .col-lg-3 .inbox {
        margin: 1em 0;
        border-radius: 0.5em;
        padding: 2em 1em 1em 1em;
        background-color: #45a29e;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        box-shadow: inset 0em 0em 0.25em 0.1em rgba(7, 7, 7, 0.692);
    }
    /* Ends */
    /* Contact Section */
    
    .contacthome {
        padding: 1em 0 2em 0;
    }
    
    .contacttxt ul li {
        margin-top: 1em;
        font-size: 1.5em;
    }
    
    .contacttxt {
        width: 60%;
        margin: 1em 40%;
    }
    
    .contacttxt h1 {
        font-size: 42px;
        width: 80%;
    }
    
    .contacttxt .contdetails {
        width: 90%;
        margin: 1em 0;
        padding: 1em 0;
        background-color: rgba(128, 128, 128, 0.212);
        border-radius: 0.5em;
        border: 0.1em solid rgba(128, 128, 128, 0.75);
        box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
    }
    
    .contacttxt .contdetails span {
        margin: 0 1em 0 1em;
        border-right: 0.15em solid rgba(128, 128, 128, 0.75);
    }
    
    .contacttxt .contdetails span i {
        padding: 0.5em 0.5em 0 0.5em;
        font-size: 2em;
    }
    
    .fa-fire {
        margin-right: 0.5em;
        padding: 0.25em 0;
        opacity: 1;
        color: #66fcf1;
    }
    /* Ends */
    /* Media Query */
    
    @media screen and (max-width:575.98px) {
        .main {
            padding: 0em 0;
            margin-bottom: 1em;
        }
        .main .home1 {
            width: 100%;
            margin: 0em 0% 0 0%;
            padding: 0em;
        }
        .quotep {
            width: 80%;
            margin: auto;
        }
        .homeimg img {
            width: 100%;
            margin: 0em auto 0 auto;
            filter: drop-shadow(1px 0px 0px white);
        }
        .dm p {
            text-align: justify;
        }
        .dm .col-lg-4 p {
            width: 80%;
            margin: 1em 10%;
            padding: 2em 0;
            text-align: center;
            background-color: rgba(128, 128, 128, 0.212);
            border-radius: 0.5em;
            border: 0.1em solid rgba(128, 128, 128, 0.75);
            box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
        }
        .service .col-lg-4 .box {
            margin: 1em auto;
            width: 100%;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em auto;
            width: 100%;
        }
        .one .row .col-lg-4 span {
            padding: 0em 0em;
            margin: 0em auto;
        }
        .howstep ul li {
            margin: 1em;
        }
        .howstep ul li span {
            width: 50%;
        }
        .howstep ul li span i {
            padding: 1em 0.5em 0.5em 0.5em;
        }
        .howstep ul li span .fa-lightbulb {
            margin-left: 0.2em;
        }
        .howstep ul li span .fa-sketch {
            margin-left: -0.15em;
            margin-top: 1em;
        }
        .howstep ul li span .fa-connectdevelop {
            margin-left: -0.25em;
            margin-top: 1em;
        }
        .howstep ul li span .fa-servicestack,
        .fa-check-circle {
            margin-left: -0.05em;
            margin-top: 1em;
        }
        .howstep ul li span .fa-check-circle {
            margin-left: -0.05em;
            margin-top: 0.85em;
        }
        .beni .col-lg-3 .inbox {
            margin: 0.5em 0;
        }
        .diff .col-lg-6 .inbox {
            margin: 0.5em 0%;
            border-radius: 0.5em;
            padding: 1em 1em 0.5em 1em;
            background-color: #45a29e;
            width: 100%;
            font-size: 2em;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }
        .contacttxt p {
            text-align: center;
        }
        .contacttxt ul li {
            margin-top: 0.5em;
            font-size: 1.5em;
        }
        .contacttxt {
            width: 100%;
            margin: 1em 0%;
        }
        .contacttxt h1 {
            text-align: center;
            font-size: 42px;
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
        }
        .contacttxt .contdetails span {
            margin: 0 1em 0 0em;
        }
        .contacttxt .contdetails span i {
            padding: 1em 0.5em 0 0.5em;
            font-size: 2em;
        }
    }
    
    @media screen and (min-width:576px) and (max-width:767.98px) {
        .main {
            padding: 0em 0;
        }
        .main .home1 {
            width: 95%;
            margin: 0em auto;
        }
        .homeimg img {
            width: 100%;
        }
        .quotep {
            width: 60%;
            margin: auto 0;
        }
        .service .col-lg-4 .box {
            width: 100%;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .diff .col-lg-6 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .marquee {
            width: 95%;
            margin: auto;
        }
        .dm .col-lg-4 p {
            width: 80%;
            margin: 1em auto;
        }
        .one {
            width: 95%;
            margin: auto;
        }
        .one .row .col-lg-4 span {
            padding: 0.5em 0em;
            margin: 0em auto;
        }
        .howstep ul li span {
            width: 25%;
        }
        .howstep ul li span i {
            padding: 0.5em 0.5em 0.5em 0.5em;
            font-size: 3em;
        }
        .howstep ul li span .fa-lightbulb {
            margin-left: 0.15em;
        }
        .beni .col-lg-3 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .contacttxt {
            width: 100%;
            margin: 1em auto;
        }
        .contacttxt p {
            text-align: center;
        }
        .contacttxt h1 {
            font-size: 42px;
            text-align: center;
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
            margin: 1em auto;
        }
    }
    
    @media screen and (min-width:768px) and (max-width:991.98px) {
        .main {
            padding: 0em 0;
        }
        .main .home1 {
            width: 95%;
            margin: 0em auto;
        }
        .homeimg img {
            width: 100%;
        }
        .quotep {
            width: 60%;
            margin: auto 0;
        }
        .service .col-lg-4 .box {
            width: 100%;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .diff .col-lg-6 .inbox {
            margin: 1em auto;
            width: 70%;
        }
        .marquee {
            width: 95%;
            margin: auto;
        }
        .dm .col-lg-4 p {
            width: 80%;
            margin: 1em auto;
        }
        .one {
            width: 95%;
            margin: auto;
        }
        .one .row .col-lg-4 span {
            padding: 0.5em 0em;
            margin: 0em auto;
        }
        .howstep ul li span {
            width: 25%;
        }
        .howstep ul li span i {
            padding: 0.5em 0.5em 0.5em 0.5em;
            font-size: 3em;
        }
        .howstep ul li span .fa-lightbulb {
            margin-left: 0.15em;
        }
        .beni .col-lg-3 .inbox {
            margin: 1em auto;
            width: 100%;
        }
        .contacttxt {
            width: 100%;
            margin: 1em auto;
        }
        .contacttxt p {
            text-align: center;
        }
        .contacttxt h1 {
            font-size: 42px;
            text-align: center;
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
            margin: 1em auto;
        }
    }
    
    @media screen and (min-width:992px) and (max-width:1199.98px) {
        .main {
            padding: 0em 0;
            margin-bottom: 1em;
        }
        .main .home1 {
            width: 100%;
            margin: 4em 0% 0 0%;
            padding: 1em;
        }
        .homeimg img {
            width: 100%;
            margin: 5em auto 0 auto;
        }
        .quotep {
            width: 60%;
        }
        .service .col-lg-4 .box {
            margin: 1em auto;
            width: 100%;
        }
        .service .col-lg-4 .boximg img {
            width: 15%;
            margin: 1em auto;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em 0%;
            width: 100%;
        }
        .diff .col-lg-6 .inbox {
            margin: 1em auto;
            width: 90%;
        }
        .dm .col-lg-4 p {
            width: 100%;
            margin: 1em 0%;
            padding: 2em 0;
            text-align: center;
            background-color: rgba(128, 128, 128, 0.212);
            border-radius: 0.5em;
            border: 0.1em solid rgba(128, 128, 128, 0.75);
            box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
        }
        .one p {
            text-align: left;
        }
        .howstep ul li {
            margin: 2em auto;
        }
        .howstep ul li span {
            width: 10%;
        }
        .contacttxt {
            width: 100%;
            margin: 1em 0%;
        }
        .contacttxt h1 {
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
        }
    }
    
    @media screen and (min-width:1200px) and (max-width:1599.98px) {
        .main {
            padding: 0em 0;
            margin-bottom: 1em;
        }
        .main .home1 {
            width: 100%;
            margin: 4em 0% 0 0%;
            padding: 1em;
        }
        .homeimg img {
            width: 80%;
            margin: 5em auto 0 auto;
        }
        .quotep {
            width: 60%;
        }
        .service .col-lg-4 .box {
            margin: 1em auto;
            width: 100%;
        }
        .service .col-lg-4 .boximg img {
            width: 15%;
            margin: 1em auto;
        }
        .industry .col-lg-3 .inbox {
            margin: 1em 0%;
            width: 100%;
        }
        .diff .col-lg-6 .inbox {
            margin: 1em auto;
            width: 90%;
        }
        .dm .col-lg-4 p {
            width: 100%;
            margin: 1em 0%;
            padding: 2em 0;
            text-align: center;
            background-color: rgba(128, 128, 128, 0.212);
            border-radius: 0.5em;
            border: 0.1em solid rgba(128, 128, 128, 0.75);
            box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
        }
        .one p {
            text-align: left;
        }
        .howstep ul li {
            margin: 2em auto;
        }
        .howstep ul li span {
            width: 10%;
        }
        .contacttxt {
            width: 100%;
            margin: 1em 0%;
        }
        .contacttxt h1 {
            width: 100%;
        }
        .contacttxt .contdetails {
            width: 100%;
        }
    }
    /* Ends */