/* .services1 {
    background-color: green;
} */

.services1 h1 {
    font-size: 50px;
    width: fit-content;
    margin: 0em auto;
    border-bottom: 0.1em solid rgba(255, 255, 255, 0);
}

.services1 h1:hover {
    color: #66fcf1;
    border-bottom: 0.1em solid #66fcf1;
}

.services1 h2 {
    color: #66fcf1;
    text-align: center;
}

.services1 p {
    text-align: justify;
}

.services1 span {
    color: #66fcf1;
}

.btnColor {
    background-color: #66fcf1;
}


/* services list */

.lservices {
    text-align: center;
}

.lservices .row {
    width: 100%;
    margin: auto;
}

.lservices .col-lg-4 {
    padding: 1em;
}

.lservices .sbox {
    margin: 1em auto;
    width: 70%;
    border-radius: 0.5em;
    padding: 1em 1em 0.5em 1em;
    border: 0.1em solid #66fcf1;
}

.lservices .col-lg-4 .sboximg img {
    width: 25%;
    margin: 1em auto;
}

.lservices .col-lg-4 .sboxtxt {
    margin: 1em auto 2em auto;
    text-transform: uppercase;
}


/* ends */


/* Service Description */

.sdesc {
    margin-top: 2em;
}

.aboutservice {
    padding: 2em 2em;
    margin: 2em auto;
    background-color: rgba(128, 128, 128, 0.212);
    border-radius: 1em;
    border: 0.1em solid rgba(128, 128, 128, 0.75);
    box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.349);
    width: 95%;
}

.aboutservice h3 {
    text-transform: uppercase;
    color: #66fcf1;
    width: fit-content;
}


/* ends */


/* Service Image */

.serviceimg img {
    width: 70%;
    margin: auto 30%;
    filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
}

.workimg img {
    width: 70%;
    margin: auto 30%;
    filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
}

.servicecontact h1,
.servicecontact h3 {
    width: fit-content;
    margin: 0em auto;
}

.servicecontact span {
    color: #66fcf1;
}


/* Ends */


/* Media Query */

@media screen and (max-width: 575.98px) {
    .lservices .sbox {
        margin: 0.5em auto;
        width: 100%;
    }
    .sdesc {
        margin-top: 0em;
    }
    .aboutservice {
        padding: 2em 2em;
        margin: 1em auto;
        width: 100%;
    }
    .serviceimg img {
        width: 100%;
        margin: auto 0%;
    }
    .workimg img {
        width: 100%;
        margin: auto 0%;
    }
    .servicecontact h1,
    .servicecontact h3 {
        text-align: center;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .serviceimg img {
        width: 100%;
        margin: auto 0%;
    }
    .workimg img {
        width: 100%;
        margin: auto 0%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {}

@media screen and (min-width: 1200px) and (max-width: 1599.98px) {}


/* Ends */