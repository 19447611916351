.about {
    margin: 2em 0;
    text-align: justify;
}

.about .row {
    margin-bottom: 1em;
}

.about-img img {
    width: 90%;
    margin: auto 25%;
    filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
}

.about-txt {
    width: 60%;
    margin: 5% 20%;
}

.abouttxt {
    margin-bottom: 2em;
}

.abouttxt h1,
.abouttxt h3 {
    font-weight: bold;
    color: #66fcf1;
}

.abouttxt .row .col-lg-12 .box {
    border: 0.1em solid #66fcf1;
    margin: 1em 0;
    padding: 1em 2em;
    background-color: rgba(128, 128, 128, 0.25);
    border-radius: 0.5em;
    box-shadow: inset 0em 0em 0.25em 0.1em rgba(255, 255, 255, 0.418);
}


/* Media Query */

@media screen and (max-width: 575.98px) {
    .about {
        margin: 0em 0;
        text-align: left;
    }
    .about-img img {
        width: 100%;
        margin: auto;
        filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
    }
    .about-txt {
        width: 100%;
        margin: 5% auto;
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
    .about {
        margin: 0em 0;
        text-align: left;
    }
    .about-img img {
        width: 100%;
        margin: auto;
        filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
    }
    .about-txt {
        width: 100%;
        margin: 5% auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
    .about {
        margin: 0em 0;
        text-align: left;
    }
    .about-img img {
        width: 100%;
        margin: auto;
        filter: drop-shadow(0px 0px 0.5px rgb(255, 255, 255));
    }
    .about-txt {
        width: 100%;
        margin: 5% auto;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {}

@media screen and (min-width: 1200px) and (max-width: 1599.98px) {}


/* Ends */