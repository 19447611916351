/* FAQ */

.faq-header {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 1em 0;
}

.faq-content {
    margin: 1em auto 3em auto;
    width: 80%;
}

.faq-question {
    padding: 20px 0;
    border-bottom: 1px solid white;
    width: 80%;
    margin: 0 auto;
}

.panel-title {
    font-size: 24px;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 10px 10px 0 50px;
    display: block;
    cursor: pointer;
    margin-left: 1em;
}

.panel-content {
    font-size: 20px;
    margin: 0 60px;
    height: 0;
    overflow: hidden;
    z-index: 1;
    position: relative;
    opacity: 0;
    -webkit-transition: 0.4s ease;
    -moz-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    transition: 0.4s ease;
    text-align: justify;
}

.panel-content ul li {
    list-style: disc !important;
    margin-left: 1em;
    padding-left: 0.5em;
}

.panel:checked~.panel-content {
    height: auto;
    opacity: 1;
    padding: 14px;
}

.plus {
    position: absolute;
    margin-left: 20px;
    margin-top: 4px;
    z-index: 5;
    font-size: 42px;
    line-height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
}

.panel:checked~.plus {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.panel {
    display: none;
}


/* Ends */


/* Privacy Policy and Terms & Conditions */

.extra {
    width: 95%;
    margin: 0 auto;
}

.extra h1 {
    font-weight: bold;
    margin: 0 auto;
    border-bottom: 0.1em solid #66fcf200;
    width: fit-content;
}

.extra h1:hover {
    color: #66fcf1;
    border-bottom: 0.1em solid #66fcf1;
}

.extra h5 {
    font-weight: bold;
}


/* Ends */


/* Media Query */

@media screen and (max-width:575.98px) {
    .faq-content {
        margin: 0em auto 2em auto;
        width: 100%;
    }
    .faq-question {
        padding: 5px 0;
        width: 100%;
    }
    .panel-title {
        font-size: 20px;
        width: 100%;
        position: relative;
        margin: 0;
        padding: 5px 5px 0 40px;
        display: block;
        cursor: pointer;
        margin-left: 0.5em;
    }
    .panel-content {
        font-size: 18px;
        margin: 0 0px;
        text-align: left;
    }
    .plus {
        margin-left: 10px;
    }
}


/* @media screen and (min-width:576px) and (max-width:767.98px) {}

@media screen and (min-width:768px) and (max-width:991.98px) {}

@media screen and (min-width:992px) and (max-width:1199.98px) {}

@media screen and (min-width:1200px) and (max-width:1599.98px) {} */


/* Ends */