/* .contactus {
    background-color: green;
} */

.contactus h1 {
    text-align: center;
}

.contactus h4 {
    text-align: center;
}

.contactfrm h1 {
    margin: 0 0 0.5em 0;
    letter-spacing: 0.05em;
}

.mcontact {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 0.5em 0;
}

.mcontact h1 {
    font-size: 50px;
    margin: 0.5em 0;
}


/* Contact Form */

.contactfrm {
    background: url('../../images/map-image.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.contactfrm .contacttxt {
    padding: 0.5em;
    margin-top: 4em;
}

.contactfrm .contdetails {
    margin: 3em 0 !important;
}

.contacttxt .contdetails span .fa-map-marker-alt {
    padding: 0.6em 0.5em 0 0.5em;
    font-size: 2.5em;
}


/* ends */


/* Google Map */

.map {
    margin: 0.5em 0;
    padding: 1em 0;
}

.map iframe {
    border-radius: 1em;
    border: 0;
    width: 80%;
    height: 60vh;
    margin: auto 10%;
    box-shadow: 0em 0em 0.5em 0.1em rgb(184, 184, 184);
}


/* Ends */