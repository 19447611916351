.seo h1 {
    width: fit-content;
    margin: 0em auto;
    border-bottom: 0.1em solid rgba(255, 255, 255, 0);
    text-align: center;
}

.seo h1:hover {
    color: #66fcf1;
    border-bottom: 0.1em solid #66fcf1;
}

.seo h2 {
    color: #66fcf1;
    margin: 1em 0 0.5em 0;
    font-weight: bold;
}

.seo span,
.seo p {
    padding: 0 2em;
    width: 100%;
}

.seo .col-lg-3 .seobox {
    padding: 1em;
    border: 0.1em solid #45a29e;
    border-radius: 0.5em;
    margin: 1em auto;
    text-align: center;
    background-color: #45a29e;
}

.seo .col-lg-4 .seobox {
    padding: 1em;
    border: 0.1em solid #45a29e;
    border-radius: 0.5em;
    margin: 1em auto;
    text-align: center;
    width: 80%;
    background-color: #45a29e;
}

.seo .col-lg-6 .seobox {
    padding: 1em;
    border: 0.1em solid #45a29e;
    border-radius: 0.5em;
    margin: 1em 25%;
    text-align: center;
    background-color: #45a29e;
    width: 50%;
}


/* Media Query */

@media screen and (max-width:575.98px) {
    .seo .col-lg-6 .seobox {
        margin: 1em 0%;
        width: 100%;
    }
}

@media screen and (min-width:576px) and (max-width:767.98px) {}

@media screen and (min-width:768px) and (max-width:991.98px) {}

@media screen and (min-width:992px) and (max-width:1199.98px) {}

@media screen and (min-width:1200px) and (max-width:1599.98px) {}


/* Ends */