/* .sitemap {
    background-color: red;
} */

.sitemap h1 {
    font-size: 50px;
    width: fit-content;
    margin: 0em auto;
    border-bottom: 0.1em solid rgba(255, 255, 255, 0);
}

.sitemap h1:hover {
    color: #66fcf1;
    border-bottom: 0.1em solid #66fcf1;
}

.sitebox h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.sitebox {
    width: 100%;
    margin: 0 auto;
    padding: 1em 2em;
}

.sitebox ul li {
    padding: 0.5em 0;
}

.sitebox ul li i {
    font-size: 1.2em;
}

.sitecontact li i {
    font-size: 1.5em !important;
    margin: 0.5em 1em 0 0;
    color: #66fcf1;
}

.sitecontact .fa-map-marker-alt {
    margin: 1.5em 1em 0 0;
}